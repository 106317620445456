import { useCallback } from 'react'

import {
  Table,
  Tooltip,
  Box,
  Badge as MantineBadge,
  Checkbox,
} from '@mantine/core'

import { Link, routes } from '@redwoodjs/router'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import { ApplicantStage } from 'src/graphql/types/applicants'
import { StepStatus } from 'src/graphql/types/steps'
import IconEmptyInbox from 'src/icons/IconEmptyInbox'
import IconRightArrow from 'src/icons/IconRightArrow'
import {
  formatDate,
  formatEnum,
  formatSidebarAddress,
  formatSnakeValue,
  formatStepType,
  buildFullName,
} from 'src/lib/formatters'
import {
  formatInternationalPhoneNumber,
  unformatPhoneNumber,
} from 'src/lib/phone.utils'

const ApplicantsTable = ({
  applicantPage = { items: [] },
  selectedApplicants,
  setSelectedApplicants,
  openDrawer,
  loading,
  onPhoneCall,
}) => {
  const onRowClick = useCallback((applicantId) => {
    openDrawer(applicantId)
  }, [])

  const applicantStageComponent = (applicant) => {
    const stageToField = {
      [ApplicantStage.PROSPECT]: 'hiringStage',
      [ApplicantStage.HIRED]: '',
      [ApplicantStage.NOT_INTERESTED]: 'notInterestedReason',
      [ApplicantStage.DISQUALIFIED]: 'disqualifiedReason',
      [ApplicantStage.TERMINATED]: 'terminatedReason',
      default: '',
    }

    const phaseField =
      stageToField[applicant.applicantStage] || stageToField.default

    return (
      <div className="flex flex-col gap-1">
        <div className="text-sm font-medium capitalize text-doubleNickel-gray-900">
          {formatSnakeValue(applicant.applicantStage)}
        </div>
        {phaseField && (
          <div className="truncate text-xs font-medium text-doubleNickel-gray-600">
            {formatSnakeValue(applicant[phaseField])}
          </div>
        )}
      </div>
    )
  }

  const callCountLabel = useCallback((callCount) => {
    if (callCount === null || callCount === 0) {
      return '0 calls'
    }
    if (callCount === 1) {
      return '1 call'
    } else if (callCount > 1) {
      return `${callCount} calls`
    }
  }, [])

  const rows = applicantPage.items?.map((applicant, index) => {
    const isHired = applicant.applicantStage === ApplicantStage.HIRED
    const goToText = isHired ? 'Go to Driver' : 'Go to Applicant'
    const tooltipText = isHired
      ? 'View driver profile'
      : 'View applicant profile'

    const applicantColumnContent = (
      <Box
        component={Link}
        to={routes.applicantDetails({
          id: applicant.applicantId,
          tab: 'application',
        })}
        className="flex flex-row items-center gap-4 whitespace-nowrap text-sm font-medium text-doubleNickel-gray-900 hover:cursor-pointer"
      >
        <Tooltip label="New applicant" withArrow>
          <MantineBadge
            size="6"
            circle
            className={`${applicant.lastActionDate ? 'invisible' : 'visible'}`}
          />
        </Tooltip>
        <div className="flex flex-col gap-1">
          <div>
            <Tooltip label={buildFullName(applicant)}>
              <div className="truncate">
                {applicant.firstName} {applicant.middleName}{' '}
                {applicant.lastName}
              </div>
            </Tooltip>
          </div>

          <div className="flex flex-row items-center gap-1 font-medium text-doubleNickel-brand-500 hover:underline">
            {goToText}
            <Tooltip label={tooltipText}>
              <div>
                <IconRightArrow className="h-3 fill-none stroke-doubleNickel-brand-500" />
              </div>
            </Tooltip>
          </div>
        </div>
      </Box>
    )

    const MAX_BADGES_DISPLAY = 6

    const stepsComponent = (steps) => {
      if (steps.length <= MAX_BADGES_DISPLAY) {
        return (
          <div className="flex flex-row gap-1 overflow-auto ">
            {steps.map((step) => renderBadge(step))}
          </div>
        )
      } else {
        // If more than MAX_BADGES_DISPLAY, show the first few and add a tooltip for the rest
        return (
          <div>
            <div className="flex flex-row gap-1  overflow-auto">
              {steps
                .slice(0, MAX_BADGES_DISPLAY)
                .map((step) => renderBadge(step))}
              {/* Additional indicator for more items */}
              <div className="text-xs font-medium text-doubleNickel-gray-600">
                +{steps.length - MAX_BADGES_DISPLAY}
              </div>
            </div>
          </div>
        )
      }
    }

    const renderBadge = (step) => {
      const color =
        step.status === StepStatus.PENDING_REVIEW
          ? 'orange'
          : step.status === StepStatus.IN_PROGRESS
          ? 'blue'
          : 'gray'
      return (
        <StyledBadge
          key={step.stepId}
          color={color}
          tooltipText={formatEnum(step.status)}
        >
          {formatStepType(step.type, true)}
        </StyledBadge>
      )
    }

    return (
      <Table.Tr
        key={applicant.applicantId}
        className="w-full text-sm font-medium text-doubleNickel-gray-900"
        onClick={() => onRowClick(applicant.applicantId)}
      >
        <Table.Td className=" px-3">
          <Checkbox
            size="xs"
            checked={
              selectedApplicants.find(
                (item) => item.applicantId === applicant.applicantId
              ) || false
            }
            onClick={(e) => {
              e.stopPropagation()
            }}
            onChange={() => {
              setSelectedApplicants((prev) =>
                prev.find((item) => item.applicantId === applicant.applicantId)
                  ? prev.filter(
                      (item) => item.applicantId !== applicant.applicantId
                    )
                  : [
                      ...prev,
                      {
                        applicantId: applicant.applicantId,
                        name: buildFullName(applicant),
                      },
                    ]
              )
            }}
          />
        </Table.Td>
        <Table.Td
          className="max-w-40 overflow-hidden px-4"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {applicantColumnContent}
        </Table.Td>
        <Table.Td className="px-4">{applicant.jobListing.title}</Table.Td>
        <Table.Td className="px-4">
          <div className="flex flex-col gap-1">
            <Box
              onClick={(event) => {
                event.stopPropagation()
                onPhoneCall({
                  applicantId: applicant.applicantId,
                  applicantFullName: buildFullName(applicant),
                  phone: unformatPhoneNumber(applicant.phone),
                })
              }}
              className={`cursor-pointer text-nowrap text-sm font-medium text-doubleNickel-brand-500 hover:underline`}
            >
              {formatInternationalPhoneNumber(applicant.phone)}
            </Box>
            <div className="truncate text-xs font-medium text-doubleNickel-gray-900">
              {callCountLabel(applicant.callCount)}
            </div>
          </div>
        </Table.Td>
        <Table.Td className="px-4">
          {formatDate(applicant.applicationDate)}
        </Table.Td>
        <Table.Td className="px-4">
          {applicantStageComponent(applicant)}
        </Table.Td>

        <Table.Td className="px-4">
          {applicant.yearsOfExperience !== null &&
          applicant.yearsOfExperience !== undefined ? (
            <div className="flex flex-col gap-1">
              {/* Years */}
              {Math.floor(applicant.yearsOfExperience)} year
              {Math.floor(applicant.yearsOfExperience) !== 1 && 's'}
              {/* Months */}
              <div className="truncate text-xs text-doubleNickel-gray-600">
                {Math.round((applicant.yearsOfExperience % 1) * 12)} month
                {Math.round((applicant.yearsOfExperience % 1) * 12) !== 1 &&
                  's'}
              </div>
            </div>
          ) : (
            '-'
          )}
        </Table.Td>
        <Table.Td className="px-4">
          <div className="flex flex-col gap-1">
            {formatSidebarAddress(applicant.address).split(',')[0]}
            <div className="truncate text-xs text-doubleNickel-gray-600">
              {formatSidebarAddress(applicant.address).split(',')[1]}
            </div>
          </div>
        </Table.Td>

        <Table.Td className="max-w-72 overflow-hidden px-4">
          {stepsComponent(applicant.steps?.items)}
        </Table.Td>
      </Table.Tr>
    )
  })

  return (
    <Table.ScrollContainer minWidth={100}>
      <Table highlightOnHover stickyHeader>
        <Table.Thead className="z-50">
          <Table.Tr className="h-11 border-none bg-doubleNickel-gray-50 text-xs text-doubleNickel-gray-600">
            <Table.Th className="w-[2%]">
              <Checkbox
                size="xs"
                checked={
                  !loading && applicantPage.items.length > 0
                    ? applicantPage.items.every((item) =>
                        selectedApplicants.find(
                          (selected) =>
                            selected.applicantId === item.applicantId
                        )
                      )
                    : false
                }
                onChange={() => {
                  if (
                    applicantPage.items.every((item) =>
                      selectedApplicants.find(
                        (selected) => selected.applicantId === item.applicantId
                      )
                    )
                  ) {
                    setSelectedApplicants((prev) =>
                      prev.filter(
                        (item) =>
                          !applicantPage.items.find(
                            (applicant) =>
                              applicant.applicantId === item.applicantId
                          )
                      )
                    )
                  } else {
                    const duplicateFreeArray = applicantPage.items.filter(
                      (item) => {
                        return !selectedApplicants.find(
                          (selected) =>
                            selected.applicantId === item.applicantId
                        )
                      }
                    )
                    const temp = duplicateFreeArray.map((item) => {
                      return {
                        applicantId: item.applicantId,
                        name: buildFullName(item),
                      }
                    })
                    setSelectedApplicants((prev) => [...prev, ...temp])
                  }
                }}
              />
            </Table.Th>
            <Table.Th className="w-[16%] px-4 ">Applicant</Table.Th>
            <Table.Th className="w-[16%] px-4">Position</Table.Th>
            <Table.Th className="w-[16%] px-4">Contact</Table.Th>
            <Table.Th className="w-[9%] px-4">Applied</Table.Th>
            <Table.Th className="w-[10%] px-4">Stage</Table.Th>
            <Table.Th className="w-[8%] px-4">Experience</Table.Th>
            <Table.Th className="w-[12%] px-4">Location</Table.Th>
            <Table.Th className="w-[10%] px-4 ">Pending Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
        <Table.Caption>
          {applicantPage.items &&
            applicantPage.items.length === 0 &&
            !loading && (
              <div className="flex flex-1 flex-col items-center justify-center  ">
                <IconEmptyInbox className="h-40 fill-none" />
                <div className="text-md font-medium text-doubleNickel-gray-800">
                  No Applicants Found
                </div>
                <div className="text-sm text-doubleNickel-gray-600">
                  Add a new applicant to get started
                </div>
              </div>
            )}
        </Table.Caption>
      </Table>
    </Table.ScrollContainer>
  )
}

export default ApplicantsTable
