import { useContext, useEffect, useState } from 'react'

import {
  Modal,
  Divider,
  Tooltip,
  Select,
  NumberInput,
  TextInput,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import { useQuery, useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import Button from 'src/components/Buttons/Button/Button'
import PhoneInput from 'src/components/Inputs/PhoneInput/PhoneInput'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { RecruitersContext } from 'src/context/RecruitersContext'
import {
  GET_APPLICANTS,
  CREATE_APPLICANT,
} from 'src/graphql/applicants.graphql'
import { GET_JOB_LISTINGS } from 'src/graphql/joblistings.graphql'
import { LinkTypeDisplay } from 'src/graphql/types/trackingLinks'
import { formatSnakeValue } from 'src/lib/formatters'
import { removeCountryCode, unformatPhoneNumber } from 'src/lib/phone.utils'

const CreateApplicantModal = ({ isModalOpen, onModalClose }) => {
  const [trackingLinks, setTrackingLinks] = useState([])
  const { currentUser } = useAuth()
  const recruiters = useContext(RecruitersContext)

  const schema = z.object({
    firstName: z
      .string()
      .min(2, { message: 'First name should have at least 2 letters' })
      .max(20, { message: 'First name should have at most 20 letters' }),
    middleName: z
      .string()
      .min(0)
      .max(20, { message: 'Middle name should have at most 20 letters' }),
    lastName: z
      .string()
      .min(2, { message: 'Last name should have at least 2 letters' })
      .max(20, { message: 'Last name should have at most 20 letters' }),
    email: z
      .string()
      .trim()
      .transform((val) => (val === '' ? undefined : val))
      .optional()
      .refine((val) => !val || z.string().email().safeParse(val).success, {
        message: 'Invalid email',
      }),
    phone: z
      .string()
      .refine(
        (data) => unformatPhoneNumber(removeCountryCode(data)).length === 10,
        {
          message: 'Phone number should have 10 digits',
        }
      ),
    yearsOfExperience: z.number().min(0).max(50).optional(),
    jobListingId: z.string(),
    trackingLinkId: z.string(),
    recruiterId: z.string(),
  })

  const form = useForm({
    initialValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '', // Set to empty string to avoid uncontrolled to controlled warning
      phone: '',
      yearsOfExperience: undefined,
      jobListingId: null,
      trackingLinkId: null,
      recruiterId: null,
    },
    validate: zodResolver(schema),
  })

  const { data: jobListingsResponse, loading: jobListingsLoading } = useQuery(
    GET_JOB_LISTINGS,
    {
      variables: {
        pageInfo: { offset: 0, limit: 20 },
      },
      onError: () => {
        toast(
          'Something went wrong retrieving job listings, please try again.',
          'error'
        )
      },
    }
  )

  const jobListingOptions =
    jobListingsResponse?.jobListings.items?.map((jl) => ({
      value: jl.jobListingId,
      label: jl.title,
    })) || []

  const [addApplicant] = useMutation(CREATE_APPLICANT, {
    refetchQueries: [GET_APPLICANTS],
    onCompleted: () => {
      toast('Applicant added successfully!', 'success')
    },
    onError: () => {
      toast('Something went wrong, please try again.', 'error')
    },
  })

  const handleSubmit = (values) => {
    const input = {
      jobListingId: values.jobListingId,
      firstName: values.firstName,
      ...(values.middleName && { middleName: values.middleName }),
      lastName: values.lastName,
      ...(values.email && { email: values.email }),
      phone: unformatPhoneNumber(values.phone),
      yearsOfExperience: values.yearsOfExperience,
      trackingLinkId: values.trackingLinkId,
      recruiterId: values.recruiterId,
    }
    addApplicant({ variables: { input } })
    form.reset()
    onModalClose()
  }

  //Update tracking links when job listing changes
  useEffect(() => {
    form.setFieldValue('trackingLinkId', null)
    if (form.values.jobListingId) {
      const jobListing = jobListingsResponse.jobListings.items.find(
        (jl) => jl.jobListingId === form.values.jobListingId
      )
      const links = jobListing?.trackingLinks.map((link) => {
        const linkTypeDisplay =
          LinkTypeDisplay[link.linkType] || formatSnakeValue(link.linkType)
        const label = link.linkName?.trim()
          ? `${linkTypeDisplay}: ${link.linkName.trim()}`
          : linkTypeDisplay

        return {
          value: link.trackingLinkId,
          label,
        }
      })
      setTrackingLinks(links)
    } else {
      setTrackingLinks([])
    }
  }, [form.values.jobListingId])

  useEffect(() => {
    if (recruiters.length > 0 && !form.values.recruiterId) {
      form.setFieldValue('recruiterId', currentUser.currentEmployeeId)
    }
  }, [recruiters, currentUser.currentEmployeeId, form])

  return (
    <Modal
      opened={isModalOpen}
      onClose={onModalClose}
      classNames={{ title: 'text-lg font-bold' }}
      title="Create New Applicant"
      centered
      size="65%"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <div className="grid grid-cols-3 gap-4 pb-4">
          <TextInput
            label="First Name"
            placeholder="Type first name"
            withAsterisk
            {...form.getInputProps('firstName')}
          />
          <TextInput
            label="Middle Name"
            placeholder="Type middle name"
            {...form.getInputProps('middleName')}
          />
          <TextInput
            label="Last Name"
            placeholder="Type last name"
            withAsterisk
            {...form.getInputProps('lastName')}
          />
          <TextInput
            label="Email"
            placeholder="Type email"
            {...form.getInputProps('email')}
          />
          <PhoneInput
            label="Phone Number"
            required
            {...form.getInputProps('phone')}
          />
          <NumberInput
            label="CDL Experience (years)"
            placeholder="Type CDL Experience in numbers here"
            allowDecimal={true}
            allowNegative={false}
            decimalScale={1}
            hideControls
            {...form.getInputProps('yearsOfExperience')}
          />
          <Tooltip
            label="No job listings were found"
            disabled={jobListingOptions?.length > 0}
          >
            <Select
              label="Job Listing"
              placeholder="Select a job listing"
              data={jobListingOptions}
              withAsterisk
              searchable
              disabled={jobListingsLoading}
              {...form.getInputProps('jobListingId')}
            />
          </Tooltip>
          <Select
            key={String(form.values.trackingLinkId)}
            label="Tracking Link"
            placeholder="Select a tracking link"
            data={trackingLinks}
            withAsterisk
            searchable
            disabled={trackingLinks.length === 0}
            {...form.getInputProps('trackingLinkId')}
          />
          <Select
            label="Assigned To"
            placeholder="Select an employee"
            data={recruiters}
            withAsterisk
            searchable
            disabled={recruiters.length === 0}
            {...form.getInputProps('recruiterId')}
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <Divider className="col-span-2" />
          <Button text="Cancel" variant="outline" onClick={onModalClose} />
          <Button text="Save" type="submit" />
        </div>
      </form>
    </Modal>
  )
}

export default CreateApplicantModal
